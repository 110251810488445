import React from 'react'
import MessengerChat from '../components/common/MessengerChat'
import DefaultLayout from '../components/layout/DefaultLayout'
import ActivitySection from '../components/page/ActivitySection'
import CoverSection from '../components/page/CoverSection'
import CreatorSection from '../components/page/CreatorSection'
import PostSection from '../components/page/PostSection'
import ProgramSection from '../components/page/ProgramSection'
// custom part
import AboutSection from '../customize/AboutSection'
import { usePage } from '../pages/AppPage'
import LoadingPage from '../pages/LoadingPage'
import NotFoundPage from '../pages/NotFoundPage'

const HomePage: React.VFC = () => {
  const { loadingAppPage, appPage } = usePage(window.location.pathname)
  if (loadingAppPage) {
    return <LoadingPage />
  }
  if (!appPage) {
    return <NotFoundPage />
  }
  const sectionConverter = {
    homeActivity: ActivitySection,
    homeCover: CoverSection,
    homePost: PostSection,
    homeProgram: ProgramSection,
    homeProgramCategory: ProgramSection,
    // homeReferrer: ReferrerSection,
    homeCreator: CreatorSection,
    // homeAccordion: AccordionSection,
    messenger: MessengerChat,
  }

  return (
    <DefaultLayout {...appPage.options}>
      {appPage.appPageSections.map(section => {
        const Section = sectionConverter[section.type]
        if (!sectionConverter[section.type]) {
          return <div key={section.id}></div>
        }
        return <Section key={section.id} options={section.options} />
      })}
      <AboutSection />
    </DefaultLayout>
  )
}

export default HomePage
