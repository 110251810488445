import { Menu as ChakraMenu, MenuButton } from '@chakra-ui/react'
import { Button, Menu, Tag } from 'antd'
import React from 'react'
import './App.scss'
import Application from './Application'
import ChProfile from './assets/images/ch-profile.png'
import { ReactComponent as ContactIcon } from './assets/images/contact.svg'
import './assets/scss/customize.scss'
import { AdminMenu } from './components/common/AdminMenu'
import { RouteProps } from './components/common/AppRouter'
import { StyledFooter } from './components/common/Footer'
import { BlankIcon, BorderedItem } from './components/common/MemberProfileButton'
import Responsive from './components/common/Responsive'
import { StyledNavButton } from './components/layout/DefaultLayout/DefaultLayout.styled'
import ChContactPage from './customize/ChContactPage'
import Footer from './customize/Footer'
import CartPage from './pages/CartPage'
// custom commonhealth components
import HomePage from './pages/HomePage'
import MemberPage from './pages/MemberPage'
import OrderPage from './pages/OrderPage'
import ProgramCollectionPage from './pages/ProgramCollectionPage'
// custom original kolable page
import ProgramContentCutscenePage from './pages/ProgramContentCutscenePage'
import ProgramContentPage from './pages/ProgramContentPage'
import ProgramPage from './pages/ProgramPage/index'

export const extraRoutes: {
  [routeKey: string]: RouteProps
} = {
  home: {
    path: '/',
    pageName: <HomePage />,
  },
  program_collection: {
    path: '/programs',
    pageName: <ProgramCollectionPage />,
  },
  program: {
    path: '/programs/:programId',
    pageName: <ProgramPage />,
  },
  program_content: {
    path: '/programs/:programId/contents/:programContentId',
    pageName: <ProgramContentPage />,
  },
  program_content_collection: {
    path: '/programs/:programId/contents',
    pageName: <ProgramContentCutscenePage />,
  },
  customer_support_link: {
    path: '/settings/contact',
    pageName: <ChContactPage />,
  },
  member: {
    path: '/members/:memberId',
    pageName: <MemberPage />,
  },
  order: {
    path: '/orders/:orderId',
    pageName: <OrderPage />,
  },
  cart: {
    path: '/cart',
    pageName: <CartPage />,
  },
}

const App: React.VFC = () => {
  return (
    <Application
      appId="commonhealth"
      extraRouteProps={extraRoutes}
      // custom menu render
      customRender={{
        renderCartButton: () => null,
        renderMemberProfile: ({ name }) => (
          <>
            <div className="profile-box">
              <div className="profile">
                <img src={ChProfile} alt="profile" />
              </div>
              <h3 className="profile-name fc-black">您好，{name}</h3>
            </div>
          </>
        ),
        renderAuthButton: () => (
          <Button
            className="button login border-primary fc-primary"
            onClick={() => {
              const host = window.location.origin
              const oauthRoot = process.env.REACT_APP_COMMONHEALTH_OAUTH_BASE_ROOT || 'https://dev-account.cwg.tw'
              const oauthClientId = process.env.REACT_APP_COMMONHEALTH_OAUTH_CLIENT_ID || '74'
              const href =
                `${oauthRoot}/oauth/v1.0/authorize?response_type=code&client_id={{CLIENT_ID}}&redirect_uri={{REDIRECT_URI}}&state={{STATE}}&scope={{SCOPE}}`
                  .replace('{{CLIENT_ID}}', oauthClientId)
                  .replace('{{REDIRECT_URI}}', encodeURIComponent(`${host}/oauth2/commonhealth`))
                  .replace('{{SCOPE}}', 'social')
                  .replace(
                    '{{STATE}}',
                    btoa(
                      JSON.stringify({
                        provider: 'commonhealth',
                        redirect: window.location.pathname,
                      }),
                    ),
                  )
              ;(window as any).dataLayer = (window as any).dataLayer || []
              ;(window as any).dataLayer.push({
                event: 'login',
              })
              window.location.assign(href)
            }}
          >
            登入
          </Button>
        ),
        renderMemberAdminMenu: ({ menuProps, defaultMenuItems }) => {
          const customerSupportIndex = defaultMenuItems.findIndex(v => v.key === 'customer_support_link')
          defaultMenuItems[customerSupportIndex < 0 ? defaultMenuItems.length : customerSupportIndex] = {
            key: 'customer_support_link',
            item: (
              <Menu.Item key="customer_support_link">
                <ContactIcon className="chakra-icon mr-2 contact-icon" /> 聯絡客服
              </Menu.Item>
            ),
          }
          const customMenuItems = defaultMenuItems.filter(v => v.key !== 'member_profile_admin').map(v => v.item)
          return <AdminMenu style={{ background: 'transparent', border: 'none' }}>{customMenuItems}</AdminMenu>
        },
        renderFooter: ({ DefaultFooter }) => (
          <StyledFooter>
            <Footer />
          </StyledFooter>
        ),
        renderAuthModal: visible =>
          visible ? (
            (() => {
              const host = window.location.origin
              const oauthRoot = process.env.REACT_APP_COMMONHEALTH_OAUTH_BASE_ROOT || 'https://dev-account.cwg.tw'
              const oauthClientId = process.env.REACT_APP_COMMONHEALTH_OAUTH_CLIENT_ID || '74'
              const href =
                `${oauthRoot}/oauth/v1.0/authorize?response_type=code&client_id={{CLIENT_ID}}&redirect_uri={{REDIRECT_URI}}&state={{STATE}}&scope={{SCOPE}}`
                  .replace('{{CLIENT_ID}}', oauthClientId)
                  .replace('{{REDIRECT_URI}}', encodeURIComponent(`${host}/oauth2/commonhealth`))
                  .replace('{{SCOPE}}', 'social')
                  .replace(
                    '{{STATE}}',
                    btoa(
                      JSON.stringify({
                        provider: 'commonhealth',
                        redirect: window.location.pathname,
                      }),
                    ),
                  )
              ;(window as any).dataLayer = (window as any).dataLayer || []
              ;(window as any).dataLayer.push({
                event: 'login',
              })
              window.location.assign(href)
              return <></>
            })()
          ) : (
            <></>
          ),
        renderOrderStatusTag: ({ status, defaultStatusTag }) => {
          if (status === 'REFUND') {
            return <Tag color="#9b9b9b">訂單已取消</Tag>
          }
          return defaultStatusTag
        },
        renderMyPageNavItem: ({ memberId }) => (
          <>
            <Responsive.Default>
              <BorderedItem
                onClick={() => (window.location.href = `/members/${memberId}`)}
                style={{ cursor: 'pointer' }}
              >
                <BlankIcon className="mr-2" />
                我的課程
              </BorderedItem>
            </Responsive.Default>
            <Responsive.Desktop>
              <ChakraMenu>
                <MenuButton as={StyledNavButton} onClick={() => (window.location.href = `/members/${memberId}`)}>
                  我的課程
                </MenuButton>
              </ChakraMenu>
            </Responsive.Desktop>
          </>
        ),
      }}
    />
  )
}

export default App
