import React from 'react'
import styled from 'styled-components'
const StyledFooter = styled.section`
  padding: 30px 0;
  background-color: #fff;
`
const StyledFooterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const StyledMenuList = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  width: 100%;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`
const StyledMenuListItem = styled.a`
  margin-bottom: 0 !important;
  font-size: 15px;
  color: #222;
  display: block;
  margin: 0 10px;
  position: relative;
  @media (max-width: 992px) {
    margin: 0px 6px 10px 6px !important;
  }
  &:not(:last-child)::after {
    background-color: #222;
    content: '';
    display: flex;
    height: 110%;
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    @media (max-width: 992px) {
      right: -6px;
    }
  }
  @media (max-width: 465px) {
    &:nth-child(5)::after {
      display: none;
    }
  }
`
const StyledFooterCopyright = styled.div`
  width: 100%;
  font-size: 14px;
  margin-bottom: 12px;
  a {
    margin: 0 5px;
  }
`
const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <StyledFooterWrap>
        <StyledMenuList>
          <StyledMenuListItem href="https://www.commonhealth.com.tw/" target="_blank" rel="noreferrer">
            康健官網
          </StyledMenuListItem>
          <StyledMenuListItem href="https://www.commonhealth.com.tw/magazine/latest/" target="_blank" rel="noreferrer">
            康健雜誌
          </StyledMenuListItem>
          <StyledMenuListItem href="https://www.commonhealth.com.tw/book/index.action" target="_blank" rel="noreferrer">
            康健出版
          </StyledMenuListItem>
          <StyledMenuListItem href="https://www.youtube.com/user/CommonHealthTV" target="_blank" rel="noreferrer">
            康健影音
          </StyledMenuListItem>
          <StyledMenuListItem href="https://line.me/ti/p/@commonhealth" target="_blank" rel="noreferrer">
            康健Line
          </StyledMenuListItem>
          <StyledMenuListItem href="https://www.facebook.com/commonhealth/" target="_blank" rel="noreferrer">
            康健Facebook
          </StyledMenuListItem>
          <StyledMenuListItem href="https://kb.commonhealth.com.tw/" target="_blank" rel="noreferrer">
            康健知識庫
          </StyledMenuListItem>
          <StyledMenuListItem href="https://club.commonhealth.com.tw/" target="_blank" rel="noreferrer">
            大人社團
          </StyledMenuListItem>
          <StyledMenuListItem href="https://lohas.commonhealth.com.tw/" target="_blank" rel="noreferrer">
            樂活島
          </StyledMenuListItem>
        </StyledMenuList>
        <StyledFooterCopyright>
          <p className="text-center">
            Copyright © {new Date().getFullYear()} 康健雜誌（天下生活出版股份有限公司）All rights reserved.
          </p>
        </StyledFooterCopyright>
        <StyledMenuList>
          <StyledMenuListItem href="https://www.commonhealth.com.tw/terms" target="_blank" rel="noreferrer">
            使用者條款
          </StyledMenuListItem>
          <StyledMenuListItem
            href="https://www.commonhealth.com.tw/member/service_policy"
            target="_blank"
            rel="noreferrer"
          >
            會員服務條款
          </StyledMenuListItem>
          <StyledMenuListItem href="https://www.commonhealth.com.tw/privacy_policy" target="_blank" rel="noreferrer">
            隱私權政策
          </StyledMenuListItem>
          <StyledMenuListItem href="https://www.commonhealth.com.tw/copyright" target="_blank" rel="noreferrer">
            著作權聲明
          </StyledMenuListItem>
        </StyledMenuList>
      </StyledFooterWrap>
    </StyledFooter>
  )
}

export default Footer
