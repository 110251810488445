import { SkeletonText } from '@chakra-ui/react'
import { Form, Icon, Input, Typography } from 'antd'
import Tracking from 'lodestar-app-element/src/components/common/Tracking'
import InvoiceInput, { StyledDescription, StyledRemark } from 'lodestar-app-element/src/components/inputs/InvoiceInput'
import { useApp } from 'lodestar-app-element/src/contexts/AppContext'
import { useAuth } from 'lodestar-app-element/src/contexts/AuthContext'
import { notEmpty } from 'lodestar-app-element/src/helpers'
import { checkoutMessages as elementCheckoutMessages } from 'lodestar-app-element/src/helpers/translation'
import { useResourceCollection } from 'lodestar-app-element/src/hooks/resource'
import { getResourceByProductId } from 'lodestar-app-element/src/hooks/util'
import { groupBy } from 'ramda'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import CartProductTableCard from '../components/checkout/CartProductTableCard'
import CheckoutBlock from '../components/checkout/CheckoutBlock'
import AdminCard from '../components/common/AdminCard'
import DefaultLayout from '../components/layout/DefaultLayout'
import CartContext from '../contexts/CartContext'
import { checkoutMessages } from '../helpers/translation'
import { useMember } from '../hooks/member'

const CartPage: React.VFC = () => {
  const location = useLocation<{ productUrn?: string }>()
  const { formatMessage } = useIntl()
  const [checkoutAlready, setCheckoutAlready] = useState(false)
  const [shopId] = useQueryParam('shopId', StringParam)
  const { cartProducts } = useContext(CartContext)
  const { id: appId } = useApp()
  const { isAuthenticating, currentMemberId } = useAuth()
  const { loadingMember, member } = useMember(currentMemberId || '')
  const cartProductGroups = groupBy(cartProduct => cartProduct.shopId || '', cartProducts)
  const shopIds = Object.keys(cartProductGroups)
  // "Scroll To Top" every cart router change if not top
  useEffect(() => {
    // DefaultLayout component ID : layout-content
    const layoutContent = document.getElementById('layout-content')
    if (layoutContent?.scrollTop !== 0) {
      layoutContent?.scrollTo(0, 0)
    }
  }, [shopId])
  const cartProductWithUrns = cartProducts.map(cartProduct => {
    const { type, target } = getResourceByProductId(cartProduct.productId)
    return { urn: `${appId}:${type}:${target}`, ...cartProduct }
  })
  const { resourceCollection } = useResourceCollection(cartProductWithUrns.map(p => p.urn))
  const filteredResourceCollection = resourceCollection.filter(notEmpty).map(resource => ({
    ...resource,
    options: { quantity: cartProductWithUrns.find(p => p.urn === resource.urn)?.options?.quantity },
  }))

  const filteredResourceUrns = filteredResourceCollection.map(resource => resource.urn)

  if (isAuthenticating || loadingMember) {
    return (
      <DefaultLayout>
        <SkeletonText mt="1" noOfLines={4} spacing="4" />
      </DefaultLayout>
    )
  }

  return (
    <DefaultLayout>
      {!checkoutAlready &&
        (location.state?.productUrn ? filteredResourceUrns.includes(location.state.productUrn) : true) &&
        filteredResourceCollection.length > 0 && (
          <Tracking.ViewCart resources={filteredResourceCollection} onViewCart={() => setCheckoutAlready(true)} />
        )}

      {/* group cart products by product owner */}
      {shopIds.length > 1 && typeof shopId === 'undefined' ? (
        <div className="container py-5">
          <Typography.Title level={3} className="mb-4">
            <Icon type="shopping-cart" className="mr-2" />
            <span>{formatMessage(checkoutMessages.title.chooseCart)}</span>
          </Typography.Title>

          {shopIds.map(shopId => (
            <CartProductTableCard
              key={shopId}
              className="mb-3"
              shopId={shopId}
              cartProducts={cartProductGroups[shopId]}
              withCartLink
            />
          ))}
        </div>
      ) : shopIds.length === 1 || (typeof shopId === 'string' && shopId === '') ? (
        <CheckoutBlock member={member} shopId={shopIds[0] || ''} cartProducts={cartProducts} />
      ) : typeof shopId === 'string' && shopId !== '' ? (
        <CheckoutBlock
          member={member}
          shopId={shopId}
          cartProducts={cartProducts.filter(cartProduct => cartProduct.shopId === (shopId || ''))}
          renderInvoice={({ invoice, setInvoice, isValidating }) => (
            <AdminCard>
              <InvoiceInput
                value={invoice}
                onChange={value =>
                  setInvoice({ ...value, name: invoice.name, email: invoice.email, phone: invoice.phone })
                }
                isValidating={isValidating}
                renderMemberInfoInput={({ value, nameRef, phoneRef, emailRef }) => (
                  <div style={{ display: 'none' }}>
                    <Input
                      ref={nameRef}
                      placeholder={formatMessage(checkoutMessages.form.message.nameText)}
                      defaultValue={member?.name || ''}
                    />
                    <Input
                      ref={phoneRef}
                      placeholder={formatMessage(checkoutMessages.form.message.phone)}
                      defaultValue={member?.phone || ''}
                    />
                    <Input
                      ref={emailRef}
                      placeholder={formatMessage(elementCheckoutMessages.message.emailText)}
                      defaultValue={member?.email || ''}
                    />
                  </div>
                )}
                renderDescription={() => (
                  <StyledDescription className="mb-4">
                    <div className="mb-1">
                      你的發票姓名、手機、email 與您註冊時填寫的會員資料相同，中獎時會主動通知。
                    </div>
                    <div>
                      若需要修改密碼、會員資料，
                      <a className="primary" href="https://www.commonhealth.com.tw/member/benefits">
                        請點此
                      </a>
                      ，登入後進行修改。
                    </div>
                  </StyledDescription>
                )}
                renderUniformNumber={({
                  value,
                  uniformNumberRef,
                  uniformTitleRef,
                  handleChange,
                  isValidating,
                  errorFields,
                }) => (
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <Form.Item
                        label={formatMessage(elementCheckoutMessages.label.uniformNumber)}
                        required
                        validateStatus={isValidating && errorFields.includes('uniformNumber') ? 'error' : undefined}
                      >
                        <Input
                          ref={uniformNumberRef}
                          placeholder={formatMessage(elementCheckoutMessages.message.uniformNumberText)}
                          defaultValue={value ? value.uniformNumber : undefined}
                          onBlur={handleChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-lg-6">
                      <Form.Item
                        label={formatMessage(elementCheckoutMessages.label.uniformTitle)}
                        required
                        validateStatus={isValidating && errorFields.includes('uniformTitle') ? 'error' : undefined}
                      >
                        <Input
                          ref={uniformTitleRef}
                          placeholder={formatMessage(elementCheckoutMessages.message.uniformTitleText)}
                          defaultValue={value ? value.uniformTitle : undefined}
                          onBlur={handleChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <StyledRemark>備註：統編發票將直接寄送至您註冊時留下的電子信箱內</StyledRemark>
                    </div>
                  </div>
                )}
              />
            </AdminCard>
          )}
          // isFieldsValidate={() => ({ isValidInvoice: true, isValidShipping: true })}
        />
      ) : (
        <div className="container py-5">
          <Typography.Title level={3} className="mb-4">
            <Icon type="shopping-cart" className="mr-2" />
            <span>{formatMessage(checkoutMessages.title.cart)}</span>
          </Typography.Title>

          <CartProductTableCard className="mb-3" shopId="" cartProducts={cartProducts} />
        </div>
      )}
    </DefaultLayout>
  )
}

export default CartPage
