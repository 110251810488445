import { SkeletonText } from '@chakra-ui/react'
import { useApp } from 'lodestar-app-element/src/contexts/AppContext'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ContactIcon } from '../assets/images/contact.svg'
import DefaultLayout from '../components/layout/DefaultLayout'
import MemberAdminLayout from '../components/layout/MemberAdminLayout'

const StyledContactWrap = styled.div`
  max-width: 525px;
  width: 100%;
`
const StyledTitle = styled.h3`
  margin-bottom: 20px;
`
const StyledP = styled.p`
  margin-bottom: 20px;
  &.no-gap {
    margin-bottom: 0;
  }
`
const StyledLink = styled.a`
  color: #009944;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: #00802b;
  }
`
const ChContactPage: React.VFC = () => {
  //useGAPageView()
  const { loading } = useApp()

  if (loading) {
    return (
      <DefaultLayout>
        <SkeletonText mt="1" noOfLines={4} spacing="4" />
      </DefaultLayout>
    )
  }

  return (
    <MemberAdminLayout content={{ icon: ContactIcon, title: '聯絡客服' }}>
      <StyledContactWrap>
        <StyledTitle>親愛的用戶您好：</StyledTitle>
        <StyledP>
          若您需要更新訂單資訊、退訂單、訂單交易失敗、詢問課程問題等服務， 請撥打客服專線，我們將有專人為您服務，謝謝。
        </StyledP>
        <StyledP>
          客服專線：
          <StyledLink href="tel:+886-2-26620332">(02)2662-0332</StyledLink>（服務時間：週一至週五09:00-17:30）
        </StyledP>
        <StyledP className="no-gap">
          讀者信箱：
          <StyledLink href="mailto:bill@cw.com.tw" target="_blank" rel="noreferrer">
            bill@cw.com.tw
          </StyledLink>
        </StyledP>
      </StyledContactWrap>
    </MemberAdminLayout>
  )
}

export default ChContactPage
