export const colors = {
  white: 'white',
  orange: ' #ff2f1a',
  gray1: '#585858',
  gray2: ' #58585803',
  gray3: '#4a4a4a',
  teal: '#009d96',
  teal2: '#21b1b1',
  teal3: '#f7f8f8',
}
