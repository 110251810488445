import { defineMessages } from 'react-intl'

const examMessages = {
  '*': defineMessages({
    item: { id: 'exam.*.item', defaultMessage: '項目' },
    personalPerformance: { id: 'exam.*.personalPerformance', defaultMessage: '個人表現' },
    overallAverage: { id: 'exam.*.overallAverage', defaultMessage: '全體平均' },
  }),
  ExamBlock: defineMessages({
    countdown: { id: 'exam.ExamBlock.countdown', defaultMessage: '倒數' },
    notFound: { id: 'exam.ExamBlock.notFound', defaultMessage: '找不到資料' },
    examNoLongerExists: { id: 'exam.ExamBlock.examNoLongerExists', defaultMessage: '題目內容已不存在' },
  }),
  ExamIntroBlock: defineMessages({
    start: { id: 'exam.ExamIntroBlock.start', defaultMessage: '開始進行' },
    unStarted: { id: 'exam.ExamIntroBlock.unStarted', defaultMessage: '尚未開始' },
    expired: { id: 'exam.ExamIntroBlock.expired', defaultMessage: '已過期' },
    duration: { id: 'exam.ExamIntroBlock.duration', defaultMessage: '測驗期間' },
    pass: { id: 'exam.ExamIntroBlock.pass', defaultMessage: '及格' },
    fullScore: { id: 'exam.ExamIntroBlock.fullScore', defaultMessage: '滿分' },
    timeLimit: { id: 'exam.ExamIntroBlock.timeLimit', defaultMessage: '答題時間' },
    retest: { id: 'exam.ExamIntroBlock.retest', defaultMessage: '重新測驗' },
    unlimited: { id: 'exam.ExamIntroBlock.unlimited', defaultMessage: '無限制' },
    limitOnce: { id: 'exam.ExamIntroBlock.limitOnce', defaultMessage: '限 1 次' },
    questionsCount: { id: 'exam.ExamIntroBlock.questionsCount', defaultMessage: '題目數' },
    questionsCountContent: { id: 'exam.ExamIntroBlock.questionsCountContent', defaultMessage: '共 {count} 題' },
    timeLimitContent: { id: 'exam.ExamIntroBlock.timeLimitContent', defaultMessage: '限時 {amount} {unit}內' },
    sec: { id: 'exam.ExamIntroBlock.sec', defaultMessage: '秒' },
    min: { id: 'exam.ExamIntroBlock.min', defaultMessage: '分鐘' },
    hour: { id: 'exam.ExamIntroBlock.hour', defaultMessage: '小時' },
    day: { id: 'exam.ExamIntroBlock.day', defaultMessage: '天' },
    week: { id: 'exam.ExamIntroBlock.week', defaultMessage: '週' },
    month: { id: 'exam.ExamIntroBlock.month', defaultMessage: '月' },
    year: { id: 'exam.ExamIntroBlock.year', defaultMessage: '年' },
    unknownUnit: { id: 'exam.ExamIntroBlock.unknownUnit', defaultMessage: '未知單位' },
    fromNowOn: { id: 'exam.ExamIntroBlock.fromNowOn', defaultMessage: '即日起' },
  }),
  ExamQuestionBlock: defineMessages({
    spendTime: { id: 'exam.ExamQuestionBlock.spendTime', defaultMessage: '花費時間' },
    averageCorrectRate: { id: 'exam.ExamQuestionBlock.averageCorrectRate', defaultMessage: '平均正確率' },
    prevQuestion: { id: 'exam.ExamQuestionBlock.prevQuestion', defaultMessage: '上一題' },
    nextQuestion: { id: 'exam.ExamQuestionBlock.nextQuestion', defaultMessage: '下一題' },
    submit: { id: 'exam.ExamQuestionBlock.submit', defaultMessage: '送出' },
    showResult: { id: 'exam.ExamQuestionBlock.showResult', defaultMessage: '查看分數' },
    correctAnswer: { id: 'exam.ExamQuestionBlock.correctAnswer', defaultMessage: '答案正確' },
    errorAnswer: { id: 'exam.ExamQuestionBlock.errorAnswer', defaultMessage: '答案錯誤' },
    correct: { id: 'exam.ExamQuestionBlock.correct', defaultMessage: '正解' },
    unanswered: { id: 'exam.ExamQuestionBlock.unanswered', defaultMessage: '未作答' },
    unansweredTime: { id: 'exam.ExamQuestionBlock.unansweredTime', defaultMessage: '作答時間不明' },
    spentTimeBySec: { id: 'exam.ExamResultBlock.spentTimeBySec', defaultMessage: '{spentTime}秒' },
  }),
  ExamResultBlock: defineMessages({
    yourExamResult: { id: 'exam.ExamResultBlock.yourExamResult', defaultMessage: '你的測驗成果' },
    score: { id: 'exam.ExamResultBlock.score', defaultMessage: '分數' },
    gainedPoints: { id: 'exam.ExamResultBlock.gainedPoints', defaultMessage: '{gainedPoints}分' },
    passExam: { id: 'exam.ExamResultBlock.passExam', defaultMessage: '恭喜！通過測驗' },
    failExam: { id: 'exam.ExamResultBlock.failExam', defaultMessage: '未通過測驗' },
    answerCorrectly: {
      id: 'exam.ExamResultBlock.answerCorrectly',
      defaultMessage: '答對 {correctCount} 題，共 {total} 題',
    },
    maxScore: { id: 'exam.ExamResultBlock.maxScore', defaultMessage: '此測驗滿分為 {maxScore} 分' },
    passingScore: { id: 'exam.ExamResultBlock.passScore', defaultMessage: '及格分數 {passingScore} 分' },
    nextCourse: { id: 'exam.ExamResultBlock.nextCourse', defaultMessage: '繼續課程' },
    showDetail: { id: 'exam.ExamResultBlock.showDetail', defaultMessage: '查看解答' },
    restartExam: { id: 'exam.ExamResultBlock.restartExam', defaultMessage: '重新測驗' },
    finishedExam: { id: 'exam.ExamResultBlock.finishedExam', defaultMessage: '已完成作答' },
    averageAnswerTime: { id: 'exam.examResultBlock.averageAnswerTime', defaultMessage: '平均答題時間' },
    totalTimeSpent: { id: 'exam.examResultBlock.totalTimeSpent', defaultMessage: '總花費時間' },
  }),
}

export default examMessages
