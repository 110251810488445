import React from 'react'
import styled from 'styled-components'
import { SectionTitle } from '../pages/AppPage'

const StyledSection = styled.section`
  padding-bottom: 80px;
  background-color: #fff;
`

const AboutSection: React.FC = () => {
  return (
    <StyledSection className="page-section">
      <SectionTitle>{'關於康健線上學習'}</SectionTitle>
      <div className="container">
        <p>
          康健線上學習是《康健雜誌》旗下的平台，我們以知識傳播為出發點，擴大朝向全方位健康生活平台發展，提供更多元的內容、產品、活動及服務。在康健線上學習平台中，我們匯集各式健康內容並聚焦於服務更多關心身心靈健康的用戶，不論何時何地，與您一起輕鬆實踐
          a Better Life。
        </p>
      </div>
    </StyledSection>
  )
}

export default AboutSection
